<template>
  <div class="move-top">
    <div class="form-brief">
      <el-image :src="require('@/assets/img/homeFormImgs/submitProject.png')" fit="cover"></el-image>
      <p class="big-title"> 上传项目 </p>
      <p class="content"> 为满足创业者精准、高效的融资需求，预见独角兽计划，旨在培育、发掘和吸引一批具有“独角兽潜质”的创业项目，为优质创业者提供一个项目曝光、1V1融资辅导、BP精准投递等多维且定制化的融资平台，同时，也为一线创业者与投资人提供更多链接机会。 </p>
      <p class="bottom"> *此服务为公益服务，若项目审核通过，会有工作人员与您取得联系。 </p>
    </div>
    <div class="form-container">
      <el-form ref="form" :label-position="isMobile ? 'top' : 'left'" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="联系人:" label-width="8rem" prop="contactPerson">
          <el-input v-model="form.contactPerson" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="职位:" label-width="8rem" prop="position">
          <el-input v-model="form.position" placeholder="请输入您的职位"></el-input>
        </el-form-item>
        <el-form-item label="手机:" label-width="8rem" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入您的手机号码"></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" label-width="8rem" prop="mailbox">
          <el-input v-model="form.mailbox" placeholder="请输入您的邮箱"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" label-width="8rem" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="项目名称:" label-width="8rem" prop="projectName">
          <el-input v-model="form.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="融资状态:" label-width="8rem" prop="financingId">
          <el-select v-model="form.financingId" placeholder="请选择融资状态" width="100%">
            <el-option v-for="(item,index) in finacingStatusList" :key="index" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目简介:" label-width="8rem" prop="synopsis">
          <el-input v-model="form.synopsis" :autosize="{ minRows: 4}" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="上传附件:" label-width="8em">
          <el-upload
            class="upload-container"
            action="https://xinke.thinkervc.cn/api/venus/resource/upload"
            :before-upload="beforeUpload"
            :on-progress="handleProgress"
            :on-change="handleChange"
            :on-error="handleError"
            :on-success="handleSuccess"
            name="file"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="" label-width="8em">
          <div class="bottom-btn" @click="submitForm('form')"> 提交 </div>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { addEntrepreneurProject, getEntrepreneurFinancingList } from '@/api'
export default {
  data() {
    // 检测手机号码正则
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('电话不能为空'));
      }
      if (String(value).length !== 11) {
        callback(new Error('输入的号码长度有问题'));
      }
      var telReg = !!String(value).match(/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/)
      if (!telReg) {
        callback(new Error('请输入真实电话号码'));
      }
      callback()
    };
    // 检测邮箱正则方法
    let checkEmail = (rule, value, callback) => {
      var reg = new RegExp('^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$')
      if (!reg.test(value)) {
        callback(new Error('请输入真实的邮箱'));
      }
      callback()
    }
    return {
      labelPosition: 'left',
      // form: {
      //   contactPerson: '',
      //   position: '',
      //   name: '',
      //   phone: '',
      //   mailbox: '',
      //   companyName: '',
      //   projectName: '',
      //   financingId: '',
      //   synopsis: '',
      //   fileList: ''
      // },
      form: {
        contactPerson: '',
        position: '',
        phone: '',
        mailbox: '',
        companyName: '',
        projectName: '',
        financingId: '',
        synopsis: '',
        uploadAnnex: ''
      },
      fileList: [],
      rules: {
        contactPerson: [
          { required: true, message: '请输入联系人', trigger: ['blur', 'change'] }
        ],
        position: [
          { required: true, message: '请输入职位', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: '电话号码不能为空', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        mailbox: [
          { required: true, message: '请输入邮箱', trigger: ['blur', 'change'] },
          { validator: checkEmail, message: '请输入准确邮箱地址', trigger: 'blur' }
        ],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: ['blur', 'change'] }
        ],
        projectName: [
          { required: true, message: '请输入项目名称', trigger: ['blur', 'change'] }
        ],
        financingId: [
          { required: true, message: '请选择融资状态', trigger: ['blur', 'change'] }
        ],
        synopsis: [
          { required: true, message: '请输入项目简介', trigger: ['blur', 'change'] }
        ]
      },
      finacingStatusList: [],
      // 该页面 loading 是否展示
      loading: null,
      uploadUrlList: [],

      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    filePathStr() {
      return JSON.stringify(this.uploadUrlList)
    }
  },
  created() {
    this.initBaseInfo()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form['uploadAnnex'] = this.filePathStr

          this.loading = this.$loading({
            lock: true,
            text: '表单提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          // this.form['financingId'] = 1

          addEntrepreneurProject(this.form).then(res => {
            if (res.code === 200) {
              this.$message({
                message: '提交成功',
                type: 'info',
                duration: 4000
              });
            }
            this.$refs['form'].resetFields();
            this.uploadUrlList = []
            this.fileList = []
            if (this.loading) {
              this.loading.close();
            }
          })
        }
      });
    },
    initBaseInfo() {
      getEntrepreneurFinancingList().then(res => {
        if (res.code === 200) {
          this.manageRequesBaseInfo(res.data)
        }
      })
    },
    /**
   * @function    manageRequesBaseInfo
   * @params      list [Array]
   * @description 接收一个数组,下拉框数据数组。对于不同的数据，赋值到本地 this 中的属性名
   */
    manageRequesBaseInfo(list) {
      list.some(item => {
        if (item.name === '融资状态') {
          this.finacingStatusList = item.children
          return true
        }
      });
    },
    /**
     * @function    manageRequestList
     * @params      list [Array]
     * @description 接收一个数组，如：[ {id: 1, name: '未融资' } ]
     * @return      返回一个整理好的数组，如：[ {value: 1, label: '未融资' } ]
     */
    manageRequestList(list) {
      let newList = list.map(item => {
        let obj = { value: '', label: '' }
        if (item) {
          obj.value = item.id
          obj.label = item.name
        }
        return obj
      })
      return newList
    },
    beforeUpload(file) {
      let fileMax = parseInt(file.size / 1024 / 1024)
      let isLt5M = fileMax > 5;
      if (isLt5M) {
        this.$message({
          message: '上传文件超出限制，该文件大小: ' + fileMax + 'M',
          type: 'warning'
        })
        return false
      }
    },
    handleProgress() {
      this.loading = this.$loading({
        lock: true,
        text: '文件上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    // 取得文件后缀名
    getExtname(fileName) {
      let lastIndex = fileName.lastIndexOf('.') + 1
      return fileName.substring(lastIndex)
    },
    // 附件上传
    handleChange(file, fileList) {
      let obj = { name: file.name, url: '', extname: '' }
      let extname = this.getExtname(file.name)
      obj.extname = extname
      if (this.loading) {
        this.loading.close();
      }
      this.fileList = fileList
      if (file.response && file.response.code === 200) {
        obj.url = file.response.data
        let uploadList = this.uploadUrlList
        uploadList.push(obj)
        this.uploadUrlList = uploadList
      }
      if (file.response && file.response.code !== 200) {
        this.fileList.pop()
        let msg = file.response['message']
        this.$message({
          message: msg,
          type: 'warning'
        })
      }
    },
    handleSuccess(response) {
      // if (this.loading) {
      //   this.loading.close();
      // }
      // let obj = { }
      // let uploadList = this.uploadUrlList
      // uploadList.push(response.data.url)
      // this.uploadUrlList = uploadList

      // if (response.code === 200) {
      //   let uploadList = this.uploadUrlList
      //   uploadList.push(response.data)
      //   this.uploadUrlList = uploadList
      // } else {
      //   const copyList = this.fileList
      //   this.fileList = copyList.splice(0, this.fileList.length - 1)
      //   this.$message({
      //     message: 'empty file',
      //     type: 'warning'
      //   })
      // }
    },
    handleError() {
      if (this.loading) {
        this.loading.close();
      }
      this.$message({
        message: '上传文件异常',
        type: 'warning'
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.move-top{
  margin: -1rem auto;
}
.form-brief{
  position: relative;
  width: 100%;
  height: 360px;
  padding: 2rem 0;
  color: #fff;

  .el-image{
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .big-title{
    font-size: 36px;
    padding: 3rem 0 1rem;
    font-weight: bold;
    text-align: center;
  }

  .content,.bottom{
    width: 1200px;
    padding: 1rem;
    margin: auto;
  }
  .bottom{
    font-weight: 700;
    color: #ffbb00;
  }
}

.form-container{
  width: 1200px;
  margin: auto;
  padding: 2rem;
  .el-form{
    .el-form-item{
      margin-top: 1.5rem;
      ::v-deep .el-form-item__label {
        width: 6rem;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }

      ::v-deep .el-select{
        width: 100%;
      }
    }

  }
}
.bottom-btn{
  width: 80%;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  background: linear-gradient(0deg,#f08519, #ffaa54);
  border-radius: 4px;
}
.upload-container{
  ::v-deep .el-upload-list__item {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .self-wrap-class{
    .form-brief{
      height: auto;
      .content,.bottom{
        width: 100%;
      }
    }
    .form-container{
      width: 100%;
      padding: .2rem;
    }
    .bottom-btn{
      width: 100%
    }
  }
}
</style>
